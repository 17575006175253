<template>
	<div class="container-fluid animatedParent animateOnce p-0">
		<!-- main content -->
		<div class="animated fadeInUpShort go">
			<div class="row no-gutters">
				<div class="col-md-3 white sticky">
					
					<div class="slimScrollDiv" style="position: relative; overflow: hidden; width: auto; height: 743px;">
						<div class="slimScroll" style="overflow: hidden; width: auto; height: 743px;">
							<div class="tab-content" id="v-pills-tabContent">
								<div class="tab-pane fade show active" id="w2-tab1" role="tabpanel" aria-labelledby="w2-tab1">
									<ul class="list-unstyled ">
										<li class="media p-3 b-b">
											<h5 class="s-14"> 收银员列表 </h5>
											<!-- <select id="outlet_id" class="form-control">
												<option value="{{outlet_id}}">{{outlet_name}}</option>
											</select> -->
										</li>
	
										<!-- employee item -->
										<li v-bind:key="index" @click="edit(index)" v-for="(item,index) in employeelist" :class="[ 'media p-3 b-b ',{'blue lighten-5' : index == selectedIndex}]">
											<img class="d-flex mr-3 height-50" src="../assets/img/dummy/u1.png" alt="收银员">
											<div class="media-body text-truncate">
												<small class="float-right">
													<!-- <span>10 May</span> -->
													<a href="#" class="mr-2 ml-2">
														<i class="icon-circle" v-if="item.enabled==1"></i>
														<i class="icon-circle-o" v-if="item.enabled==0"></i>
													</a>
												</small>
												<h6 class="mt-0 mb-1 font-weight-normal">{{item.employee_name}}</h6>
												<span>{{item.employee_code}}</span>
											</div>
										</li>
										<!-- //employee item -->
	
										
									</ul>
								</div>
	
							</div>
						</div>
						<div class="slimScrollBar" style="background: rgba(0, 0, 0, 0.95); width: 5px; position: absolute; top: 0px; opacity: 0.4; display: none; border-radius: 7px; z-index: 99; right: 1px; height: 340.981px;"></div>
						<div class="slimScrollRail" style="width: 5px; height: 100%; position: absolute; top: 0px; display: none; border-radius: 7px; background: rgb(51, 51, 51); opacity: 0.2; z-index: 90; right: 1px;"></div>
					</div>
				</div>
				<div class="col-md-9 b-l">
	
					<!-- 编辑区域 -->
					<div class="m-md-3">
						<div class="row my-3">
							<div class="col-md-8 offset-md-2">
								<div class="card no-b">
									<div class="card-body">
										<h5 class="card-title">{{selectedIndex==-1?'添加':'修改'}}收银员</h5>
										
										<div>
											<div class="form-group">
												<label for="inputAddress" class="col-form-label">收银员帐号</label>
												<input autocomplete="off" class="form-control" v-model="employee.employee_code" placeholder="手机号码" type="number">
											</div>
											<div class="form-group">
												<label for="inputAddress" class="col-form-label">密码</label>
												<input autocomplete="new-password" class="form-control " v-model="employee.password" placeholder="密码"
												 type="password">
												<small v-if="selectedIndex!=-1">不修改请留空</small>
											</div>
											<div class="form-group">
												<label for="inputAddress2" class="col-form-label">收银员姓名</label>
												<input class="form-control " v-model="employee.employee_name" placeholder="姓名" type="text">
											</div>
	
											<div class="form-group" v-show="categorylist.length>0">
												<label for="inputAddress2" class="col-form-label">关联菜品分类</label>
												<select v-model="employee.category_id" class="form-control" style="height:42px;">
													<option value="0">选择菜品分类</option>
													<option v-bind:key="index"  :value="item.id"  v-for="(item,index) in categorylist">{{item.category_name}}</option>
												</select>
												<small class="form-text text-muted">
													消费机菜单模式时，该收银员只显示关联分类的菜品；不使用菜单模式，可不选
												</small>
											</div>
	
											<!-- <div class="form-group">
												<label for="inputAddress2" class="col-form-label">权限</label>
												<div class="form-inline">
													<div class="form-check">
														<label class="form-check-label">
															<input class="form-check-input" type="checkbox" v-model="employee.auth" value="1"> 允许挂失操作，
														</label>
													</div>
	
													<div class="form-check">
														<label class="form-check-label">
															<input class="form-check-input" type="checkbox" v-model="employee.auth" value="1"> 允许充值操作
														</label>
													</div>
												</div>
											</div> -->
											
											<div class="form-group">
												<div class="form-check">
													<label class="form-check-label">
														<input class="form-check-input" type="checkbox" v-model="employee.disabled" value="1"> 禁用{{employee.disabled}}
													</label>
												</div>
											</div>
	
											<button v-if="selectedIndex != -1" type="button" :disabled="disabled" class="btn btn-danger" @click.stop="remove">
												删除 </button>
	
											<button type="button" :disabled="disabled" class="btn btn-primary" @click.stop="save">保存</button>
	
										</div>
	
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- //编辑区域 -->
	
				</div>
			</div>
		</div>
		<!-- //main content -->
		
		<!--Add New Message Fab Button-->
		<a @click="clear" href="#" class="btn-fab btn-fab-md fab-right fab-right-bottom-fixed shadow btn-secondary"><i class="icon-add"></i></a>
		
	</div>
</template>

<script>
	module.exports = {
		data:function(){
			return{
				title: '收银员',
				
				token: '',
				outlet_id: 0,
				outlet_name:'',
				user_name: '',
				
				employeelist: [],
				employee: {
					disabled: false,
					category_id: 0,
				},
				selectedIndex: -1,
				
				disabled: false,
				isFirst: false,
				
				categorylist: [],
			}
		},
		
		created: function() {
			console.log('--- created --- ')
			let self = this;
			
			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;
			
			//2.取得店铺信息
			let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			console.log(lsLoginData.logined_outlet_name)
			// self.outlet_id = lsLoginData.logined_outlet_id;
			console.info('--- this.$store.state ->',this.$store.state)
			self.outlet_id = this.$store.state.current_outlet_id || lsLoginData.logined_outlet_id;
			self.outlet_name = lsLoginData.logined_outlet_name;
			self.user_name = lsLoginData.user_account;
			
			self.setting = JSON.parse(localStorage.getItem('SETTING'));
			
			self.outletlist = JSON.parse(localStorage.getItem('OUTLETLIST'));
			console.log('--- outlet list ->', self.outletlist)
		},
		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;
			
			//1.检查登录
			// let token = localStorage.getItem('TOKEN');
			if (!self.token) {
				console.log('--- mounted jump  --- ')
				location.href = 'index.html';
				return;
			}
			
			self.init(() => {
				self.initData();
			});
		},
		methods: {
			initData(){
				let self = this;
				
				//调用接口
				self.axios.get(self.GLOBAL.baseURI + 'getEmployeeList', {
					params: {
						token: self.token,
						outlet_id: self.outlet_id,
					}
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					if (data.code == 200) { 
						self.employeelist = data.data;
					}
					else if (data.code == 101) {
						//登录超时
						location.href = "index.html";
					}
					else{
						alert(data.message)
					}
				});
				//END
				
			},
			
			
			init(callback){
			 	let self = this;
			 	
			 	//调用接口
			 	self.axios.get(self.GLOBAL.baseURI + 'getCategoryList', {
			 		params: {
			 			token: self.token,
			 			outlet_id: self.outlet_id,
			 			// name: '',
			 		}
			 	})
			 	.then(function(response) {
			 		console.log(response);
			 		return response.data;
			 	})
			 	.catch(function(error) {
			 		console.log(error);
			 	})
			 	.then(function(data) {
			 		// always executed
			 		console.log(data.data);
			 		if (data.code == 200) { 
			 			self.categorylist = data.data;
						
						if (typeof callback == 'function') {
							callback()
						}
			 		}
			 		else if (data.code == 101) { 
			 			//登录超时
			 			location.href = "index.html";
			 		}
			 		else{
			 			alert(data.message)
			 		}
			 	});
			 	//END
			 	
			 },
			
			edit(index){
				console.log(index);
				let self = this;
				
				let employee = self.employeelist[index];
				employee.password = '';
				
				employee.disabled = !employee.enabled;
				
				self.selectedIndex = index;
				self.employee = employee;
				console.log(self.selectedIndex,self.employee);
			},
			
			save(){
				let self = this;
				console.log(self.employee);
				// return;
				
				//校验
				if(!self.employee.employee_code){
					alert('员工帐号不能为空')
					return;
				}
				
				if(self.selectedIndex == -1 && !self.employee.password){
					alert('密码不能为空')
					return;
				}
				
				if(!self.employee.employee_name){
					alert('员工姓名不能为空')
					return;
				}
				
				self.disabled = true;
				
				// 组装数据
				let postData = {
					token: self.token,
					employee_id: self.selectedIndex!=-1?self.employee.id:'',
					outlet_id: self.outlet_id,
					employee_code: self.employee.employee_code,
					employee_name: self.employee.employee_name,
					deposit_commission_rate:0,
					shared:0,
					// enabled: self.employee.enabled!=undefined?self.employee.enabled:1,
					enabled: self.employee.disabled?0:1,
					password: self.employee.password!=undefined?self.employee.password:'',
					category_id: self.employee.category_id || 0,
				}
				
				self.axios.get(self.GLOBAL.baseURI + 'saveEmployee', {
					params: postData
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					
					self.disabled = false;
					
					if (data.code == 200) { 
						let employee = data.data;
						employee.disabled = !employee.enabled;
						
						if(self.selectedIndex==-1){
							self.employeelist.unshift(employee);
						}
						else{
							self.employeelist.splice(self.selectedIndex,1,employee);
						}
						
						
						alert('保存成功')
						
						self.clear();
					}
					else if (data.code == 101) {
						//登录超时
						location.href = "index.html";
					}
					else{
						alert(data.message)
					}
				});
			},
			
			
			remove(){
				let self = this;
				console.log(self.employee);
				// return;
				
				self.disabled = true;
				
				// 组装数据
				let postData = {
					token: self.token,
					employee_id: self.selectedIndex!=-1?self.employee.id:''
				}
				
				self.axios.get(self.GLOBAL.baseURI + 'deleteEmployee', {
					params: postData
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					
					self.disabled = false;
					
					if (data.code == 200) { 
						for(let i=0; i<self.employeelist.length; i++){
							if(self.selectedIndex == i){
								self.employeelist.splice(i,1);
							}
						}
						
						alert('删除成功')
						
						self.clear();
					}
					else if (data.code == 101) {
						//登录超时
						location.href = "index.html";
					}
					else{
						alert(data.message)
					}
				});
			},
			
			clear() {
				var self = this;
				
				self.selectedIndex = -1;
				self.employee = {
					disabled: false,
					employee_code: '',
					password: '',
					employee_name: '',
					category_id: 0,
				};
			},
			
			onOutletChangeListener(e){
				let self = this;
				console.log('--- outlet ID->',e)
				self.outlet_id = e;
				
				self.init(() => {
					self.initData();
					self.clear();
				});
				
			},
		},
	}
</script>

<style>
</style>
